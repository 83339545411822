import { Union } from "../models/unions.model";

export class LoadUnions {
  public static readonly type = "[Unions] load unions";
}

export class AddUnion {
  public static readonly type = "[Unions] add union";
  constructor(
    public payload: Union,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateUnion {
  public static readonly type = "[Unions] update union";
  constructor(
    public payload: Union,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveUnion {
  public static readonly type = "[Unions] remove union";
  constructor(
    public unionId: number,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
